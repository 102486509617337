import { BroadcastMembersIndicator } from './BroadcastMembersIndicator';
import { BroadcastIndicator, BroadcastToggle } from './BroadcastProvider';

export function LobbyBroadcastBundle(props: {
  classNames?: string;
}): JSX.Element {
  return (
    <div
      className={`flex flex-col items-center justify-center
      text-white pointer-events-off ${props.classNames || ''}`}
    >
      <div className='mb-2.5 z-5'>
        <BroadcastMembersIndicator />
      </div>
      <div className='flex gap-2 items-center justify-center'>
        <BroadcastToggle className='transform scale-115 z-5 pointer-events-on' />
        <BroadcastIndicator isLobbyOrHost={true} />
      </div>
    </div>
  );
}
