import { HollowedCheckIcon } from '../icons/CheckIcon';

export interface SelectionSection<
  T extends { label: string; value: string } = { label: string; value: string }
> {
  headerText: string;
  availableOptions: T[];
  activeOption: T | null;
  handleOptionChange: (option: T) => void;
}

interface SelectionProps {
  sections: SelectionSection[];
}
const buildSection = (
  section: SelectionSection,
  divider: boolean
): JSX.Element | null => {
  if (section.availableOptions.length === 0) {
    return null;
  }
  return (
    <section key={section.headerText}>
      <header className='text-2xs font-bold m-3 text-white'>
        {section.headerText}
      </header>
      <ul className='text-3xs font-light text-gray-200'>
        {section.availableOptions.map((o) => {
          return (
            <li
              key={o.value}
              className='p-3 hover:bg-lp-gray-001 rounded-lg truncate cursor-pointer flex flex-row items-center'
              onClick={() => section.handleOptionChange(o)}
            >
              {o.label}
              {section.activeOption?.value === o.value && (
                <span className='ml-2'>
                  <HollowedCheckIcon />
                </span>
              )}
            </li>
          );
        })}
      </ul>
      {divider && (
        <div className='border-b border-[#303436] border-dashed border-opacity-30 h-1 w-full' />
      )}
    </section>
  );
};

const Selection = (props: SelectionProps): JSX.Element => {
  return (
    <div
      className='
    bg-lp-black-001 absolute
    bottom-0 left-14 w-80 border border-[#303436] rounded-xl p-1'
    >
      <div className=' max-h-160 overflow-y-scroll scrollbar-always'>
        {props.sections.map((s, i) =>
          buildSection(s, i !== props.sections.length - 1)
        )}
      </div>
    </div>
  );
};
export { Selection };
