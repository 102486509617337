import {
  type DeviceOption,
  MediaKind,
  NoCameraDeviceOption,
  useDeviceAPI,
  useDeviceState,
} from '../Device';
import { Selection, type SelectionSection } from './Selection';

interface VideoSelectionProps {
  videoInputOptions: DeviceOption[];
  handleClose: () => void;
}

const VideoSelection = ({
  videoInputOptions,
  handleClose,
}: VideoSelectionProps): JSX.Element => {
  const api = useDeviceAPI();
  const { activeVideoInputDeviceOption } = useDeviceState();

  const handleVideoInputChange = async (option: DeviceOption) => {
    api.updateActiveDeviceOption(MediaKind.VideoInput, option);
    handleClose();
  };

  const sections: SelectionSection[] = [
    {
      headerText: 'Select a Camera',
      availableOptions:
        videoInputOptions.length > 0
          ? videoInputOptions
          : [NoCameraDeviceOption],
      activeOption: activeVideoInputDeviceOption,
      handleOptionChange: handleVideoInputChange,
    },
  ];

  return <Selection sections={sections} />;
};

export { VideoSelection };
