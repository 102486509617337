import { useLayoutEffect } from 'react';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { usePageVisibility } from '../../hooks/useVisibililityChangeEffect';
import { useExperienceMetricsManager } from '../ExperienceMetrics';
import { LONGFRAME_MS, useExperienceScoreAPI } from '../ExperienceScore';

export function FrameRateMeasure(): null {
  const expscore = useExperienceScoreAPI();
  const visible = usePageVisibility();
  const metricsManager = useExperienceMetricsManager();

  useLayoutEffect(() => {
    const periodMs = 10000;
    if (!window.performance.now || !getFeatureQueryParam('framerate-measure'))
      return;

    let running = true;

    let start = 0;
    let prev = 0;

    const tick = (now: DOMHighResTimeStamp) => {
      if (!running) return;

      // Delta could be negative if the browser was delayed in processing the
      // vsync request from the OS. This most likely happens when under heavy
      // load, such as during the initial page load (lots of synchronous
      // blocking activity). Both Chrome and Firefox use the OS/Monitor's vsync
      // request timestamp as the `now` value.
      const delta = now - prev;

      // Ensure we have at least one well-timed frame before we start reporting.
      if (visible && delta > 0 && prev > 0 && start > 0) {
        metricsManager?.markFrame(delta);

        // Only consider it a longframe if the page is visible. Background tabs
        // may have an extreme delta, otherwise, due to throttling.
        if (delta >= LONGFRAME_MS) {
          expscore.contribute('longframe', {
            durationMs: delta,
          });
        }
      }

      prev = now;

      // Avoid a negative value by avoiding performance.now()
      if (start === 0) start = now;

      if (now - start >= periodMs) {
        start = now;
        prev = now;
      }

      requestAnimationFrame(tick);
    };

    tick(start);

    return () => {
      running = false;
    };
  }, [expscore, metricsManager, visible]);

  return null;
}
