import type { SupportedLanguages } from '../../i18n/language-options';
import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import {
  type CaptionChunkMetric,
  createMetricsCreator,
} from './MetricsCreator';

class SubtitleLine {
  private open = true;
  private expireCtrl = new BrowserTimeoutCtrl();
  private widthChars = 0;
  readonly $el;

  constructor(
    private maxCharsPerLine: number,
    private expireDurationMs: number,
    classList?: string[],
    style?: Partial<CSSStyleDeclaration>
  ) {
    this.$el = document.createElement('div');
    if (classList) {
      this.$el.classList.add(...classList);
    } else {
      this.$el.classList.add(
        'inline-block',
        'bg-secondary',
        'text-white',
        'text-xl',
        'px-2',
        'whitespace-pre'
      );
    }

    if (style) {
      Object.assign(this.$el.style, style);
    }
    this.resetExpireTimer();
  }

  private resetExpireTimer() {
    this.expireCtrl.clear();
    this.expireCtrl.set(() => {
      this.exit();
    }, this.expireDurationMs);
  }

  private canAccept(word: CaptionChunkMetric) {
    return (
      this.open && this.widthChars + word.widthChars <= this.maxCharsPerLine
    );
  }

  accept(word: CaptionChunkMetric, force?: true) {
    if (!this.canAccept(word) && !force) return false;
    this.resetExpireTimer();
    this.widthChars += word.widthChars;
    this.$el.append(word.toDOM());
    return true;
  }

  exit() {
    this.expireCtrl.clear();
    // Mark this line as unable to accept more words, even if it has room!
    this.open = false;
    // remove from DOM
    this.$el.remove();
  }
}

export class SubtitlesPrinter {
  private metrics: CaptionChunkMetric[] = [];
  private lines: SubtitleLine[] = [];

  private ctrl = new BrowserTimeoutCtrl();
  private waiting = false;

  private $el = document.createElement('div');

  constructor(
    private ref: { current: HTMLDivElement | null },
    private maxCharsPerLine: number,
    private expireDurationMs: number,
    private maxLines: number,
    private classList?: string[],
    private style?: Partial<CSSStyleDeclaration>
  ) {}

  updateMaxLineLength(max: number) {
    this.maxCharsPerLine = max;
  }

  accept(text: string, lang: string) {
    const mcreator = createMetricsCreator(lang as SupportedLanguages);
    const metrics = mcreator.parse(text, lang);

    // enqueue them to be emitted into lines
    this.metrics.push(...metrics);
    this.kick();
  }

  pause() {
    this.ctrl.clear();
  }

  resume() {
    this.kick();
  }

  private kick() {
    if (this.waiting) return;

    const waitUntil = this.emitWord();
    if (waitUntil)
      this.ctrl.set(() => {
        this.waiting = false;
        this.kick();
      }, waitUntil);
  }

  private emitWord(): number {
    const word = this.metrics.shift();

    if (!word) return 0;

    // housekeeping: have we added the container to the DOM yet? We need
    // `.current` to be known first.
    if (!this.$el.parentElement) this.ref.current?.appendChild(this.$el);

    // find a line to accept the word
    const accepted = this.lines.at(-1)?.accept(word);

    if (!accepted) {
      // line is full, exit old lines, create new line
      const line = new SubtitleLine(
        this.maxCharsPerLine,
        this.expireDurationMs,
        this.classList,
        this.style
      );
      this.lines.push(line);
      this.$el.appendChild(line.$el);
      // Force adding to the new line, even if it's too long.
      line.accept(word, true);

      // remove old lines
      while (this.lines.length > this.maxLines) {
        const line = this.lines.shift();
        if (line) line.exit();
      }
    }

    return word.timeMs;
  }

  clear() {
    this.lines.forEach((line) => line.exit());
    this.lines.length = 0;
    this.metrics.length = 0;
    this.ctrl.clear();
    this.waiting = false;
  }

  destroy() {
    this.clear();
    this.$el.remove();
  }
}
