import { isStaff } from '../types';
import { useMyInstance } from './Player';
import { useSpectatorFlag } from './Player';
import { SwitcherControlled } from './Switcher';

function Label(props: { text: string; active: boolean }): JSX.Element {
  return (
    <div
      className={`text-3xs ${
        props.active ? 'text-white font-bold' : 'text-[#CCC]'
      }`}
    >
      {props.text}
    </div>
  );
}

export function SpectatorCtrlPanel() {
  const [spectatorFlag, setSpectatorFlag] = useSpectatorFlag();

  const handleChange = async (v: boolean) => {
    setSpectatorFlag(v);
  };
  return (
    <div
      className={`group-1 transform bg-black bg-opacity-50 pointer-events-auto w-10 h-10 hover:w-37.5 hover:h-auto rounded-lg p-2`}
    >
      <div className='w-full flex items-center justify-center group-1-hover:hidden text-white text-center font-bold'>
        {spectatorFlag ? 'S' : 'T'}
      </div>
      <div className='text-2xs text-white hidden group-1-hover:flex'>
        {!!spectatorFlag
          ? 'You can hear and see the players in Townhall, but they cannot see or hear you even if your mic is unmuted.'
          : 'You can see the moderators in your team, you can hear them if they are not in spectator mode.'}
      </div>
      <div className='items-center justify-center px-3 py-1 rounded-2xl text-white hidden group-1-hover:flex'>
        <Label text='Teams' active={!spectatorFlag} />
        <div className='mx-2'>
          <SwitcherControlled
            name='spectator-mode'
            checked={!!spectatorFlag}
            onChange={handleChange}
          />
        </div>
        <Label text='Spectator' active={!!spectatorFlag} />
      </div>
    </div>
  );
}

export function SpectatorCtrlButton() {
  const me = useMyInstance();
  const meIsStaff = isStaff(me);

  if (!meIsStaff) return null;

  return (
    <div className='absolute my-1.5 transform -translate-y-full -top-3'>
      <SpectatorCtrlPanel />
    </div>
  );
}
