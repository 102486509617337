export function ClosedCaptionsEnabledIcon() {
  return (
    <div className='mt-0.75 flex flex-col uppercase font-bold text-white'>
      <div className='w-6 h-4.5 rounded-md flex justify-center items-center text-2xs border-2 border-white'>
        CC
      </div>
      <span className='text-[0.5625rem] text-green-001'>On</span>
    </div>
  );
}

export function ClosedCaptionsDisabledIcon() {
  return (
    <div className='mt-0.75 flex flex-col uppercase font-bold text-icon-gray'>
      <div className='w-6 h-4.5 rounded-md flex justify-center items-center text-2xs border-2 border-gray-001'>
        CC
      </div>
      <span className='text-[0.5625rem]'>Off</span>
    </div>
  );
}
