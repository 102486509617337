import { type CSSProperties } from 'react';

import { EnterExitTailwindTransition } from '../../../components/common/TailwindTransition';
import { LayoutAnchor } from '../../../components/LayoutAnchors/LayoutAnchors';
import {
  usePersistentPointsBadgeScale,
  usePersistentPointsScore,
} from '../../../components/PersistentPoints/Provider';
import { usePointsBadgeIsActive } from '../../../components/PersistentPoints/usePointsBadgeIsActive';

export function PointsBadge(props: {
  scale: number | null;
  points: number;
}): JSX.Element {
  const twScaleProps = {
    '--tw-scale-x': props.scale === null ? 1 : props.scale,
    '--tw-scale-y': props.scale === null ? 1 : props.scale,
  };

  return (
    <div
      className={`
        relative
        bg-points-badge bg-no-repeat bg-bottom
        w-full h-full flex flex-col justify-center items-center
        font-cairo text-black
      `}
    >
      <div
        className='
          w-full text-center font-extrabold text-xl leading-5
          transition-transform duration-300 transform
      '
        style={{ ...twScaleProps } as CSSProperties}
      >
        {Intl.NumberFormat('en-US', {
          notation: 'compact',
          maximumSignificantDigits: 3,
        }).format(props.points)}
      </div>
      <div className='w-full text-center text-3xs'>Points</div>
    </div>
  );
}

export function MiniPointsBadge(): JSX.Element | null {
  const pointsBadgeIsEnabled = usePointsBadgeIsActive();
  const points = usePersistentPointsScore();
  const scale = usePersistentPointsBadgeScale();

  return !pointsBadgeIsEnabled ? null : (
    <div className='w-19 h-12'>
      <LayoutAnchor
        id='points-badge-mini'
        className={`
          absolute w-full h-full pointer-events-off
        `}
      />
      <EnterExitTailwindTransition
        initialClasses={'translate-x-full opacity-0'}
        enteredClasses={`translate-x-0 opacity-100`}
      >
        {(ref, initial) => (
          <div
            ref={ref}
            className={`w-full h-full transition-all duration-500 transform ${initial}`}
          >
            <PointsBadge points={points} scale={scale} />
          </div>
        )}
      </EnterExitTailwindTransition>
    </div>
  );
}

export function TeamHeaderPointsBadge(): JSX.Element | null {
  const pointsBadgeIsEnabled = usePointsBadgeIsActive();
  const points = usePersistentPointsScore();
  const scale = usePersistentPointsBadgeScale();
  const enteredTranslate = 'translate-x-[-5px]';
  return !pointsBadgeIsEnabled ? null : (
    <div className={`self-end w-19 h-12 relative`}>
      <LayoutAnchor
        id='points-badge-team-header'
        className={`
          absolute w-full h-full pointer-events-off
          transform ${enteredTranslate}
        `}
      />
      <EnterExitTailwindTransition
        initialClasses={'translate-x-19 opacity-0'}
        enteredClasses={`${enteredTranslate} opacity-100`}
      >
        {(ref, initial) => (
          <div
            ref={ref}
            className={`w-full h-full transition-all duration-700 transform ${initial}`}
          >
            <PointsBadge points={points} scale={scale} />
          </div>
        )}
      </EnterExitTailwindTransition>
    </div>
  );
}
