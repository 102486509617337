import { MegaphoneIcon } from '../icons/MegaphoneIcon';
import { useParticipantByClientId } from '../Player';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { useBroadcastMembers } from './BroadcastProvider';

export const BroadcastMembersIndicator = (): JSX.Element => {
  const myClientId = useMyClientId();
  const members = useBroadcastMembers({ sort: true, myClientId });
  const first = useParticipantByClientId(members[0]?.id);

  let text: string | null = null;

  if (members.length === 1) {
    text = `${first?.username} is talking`;
  } else if (members.length === 2) {
    text = `${first?.username} and 1 other are talking`;
  } else if (members.length > 2) {
    text = `${first?.username} and ${members.length - 1} others are talking`;
  }

  if (text) {
    text = `Broadcasts: ${text}`;
  }

  return (
    <>
      {text && (
        <div className='font-medium text-3xs whitespace-nowrap flex items-center'>
          {/* Absolutely position and y-translate the icon so it can be perfectly
              vertically aligned without expanding the container. We need the container
              to be the same height as the Expand Crowd button */}
          <div className='relative w-3 h-3 flex-shrink-0'>
            <MegaphoneIcon
              className={`
                w-4 h-4 fill-current text-white
                absolute top-1/2 transform -translate-y-1/2
              `}
            />
          </div>
          <span className='pl-2 text-white truncate'>{text}</span>
        </div>
      )}
    </>
  );
};
