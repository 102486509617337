import { useIsCoordinator } from '../../../../../hooks/useMyInstance';
import {
  ConfirmCancelModalBox,
  ConfirmCancelModalHeading,
} from '../../../../ConfirmCancelModalContext/useCancelConfirmModalStateRoot';
import { PauseIcon } from '../../../../icons/PauseIcon';
import { PlayIcon } from '../../../../icons/PlayIcon';
import {
  useOndGameUIControl,
  useOndGameUIControlState,
} from '../../../../OnDGameUIControl';
import { useParticipant } from '../../../../Player';
import { useGameHostingCoordinator } from '../../../GameHostingProvider';
import { useOndGameResumingProgress, useOndGameState } from '../../../hooks';

export const OndGamePlayOverlay = (): JSX.Element => {
  const coordinator = useGameHostingCoordinator();
  const pCoordinator = useParticipant(coordinator?.clientId);
  const ondState = useOndGameState();
  const resumingProgress = useOndGameResumingProgress();
  const isCoordinator = useIsCoordinator();
  const ondUICtrl = useOndGameUIControl();
  const { actionDisabled } = useOndGameUIControlState();

  let contentText = null;
  if (ondState === 'paused') {
    contentText = (
      <div className='flex justify-center items-center'>
        <PauseIcon className='mr-2 w-6 h-6'></PauseIcon>
        <div>Game paused {pCoordinator && `by ${pCoordinator.username}`}</div>
      </div>
    );
  } else if (ondState === 'resuming') {
    contentText = 'Game resuming in';
  }

  const onResetGame = () => {
    if (!ondUICtrl) return;
    ondUICtrl.onClickReset();
  };

  const onResumeGame = () => {
    if (!ondUICtrl) return;
    ondUICtrl.onClickPauseResume('resumeGame');
  };

  const coordinatorModal =
    isCoordinator && ondState === 'paused' ? (
      <ConfirmCancelModalBox
        className='w-96 h-37 pt-5 px-7.5 pb-7.5'
        borderVariant='black'
      >
        <ConfirmCancelModalHeading>Game Paused</ConfirmCancelModalHeading>
        <div className='flex-grow-0 flex gap-4'>
          <button
            type='button'
            className='btn-secondary tracking-wider w-38 h-10'
            onClick={onResetGame}
            disabled={actionDisabled}
          >
            Reset Game
          </button>
          <button
            type='button'
            className={`
              btn-primary tracking-wider w-38 h-10
              flex justify-center items-center gap-3
            `}
            onClick={onResumeGame}
            disabled={actionDisabled}
          >
            <PlayIcon className='w-5 h-5 fill-current' />
            Resume
          </button>
        </div>
      </ConfirmCancelModalBox>
    ) : null;

  if (!contentText && !coordinatorModal) return <></>;

  return (
    <div
      className={`
        z-25
        absolute w-full h-full gap-4
        bg-lp-black-004
        flex flex-col items-center justify-center
    `}
    >
      {coordinatorModal ? (
        coordinatorModal
      ) : (
        <>
          <header className='text-white text-xl font-bold'>
            {contentText}
          </header>
          {ondState === 'resuming' && (
            <span className='text-red-002 text-6xl font-bold h-52'>
              {resumingProgress}
            </span>
          )}
        </>
      )}
    </div>
  );
};
