import { useRef } from 'react';
import { useToggle } from 'react-use';

import { RenameTeam } from '../../../../components/AllTeams';
import { BroadcastToggle } from '../../../../components/Broadcast';
import {
  ArrowDownIcon,
  ArrowUpIcon,
} from '../../../../components/icons/Arrows';
import { EditIcon } from '../../../../components/icons/EditIcon';
import { TeamIcon } from '../../../../components/icons/TeamIcon';
import { usePointsBadgeIsActive } from '../../../../components/PersistentPoints/usePointsBadgeIsActive';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { type TeamV0 } from '../../../../types/team';
import { type Participant } from '../../../../types/user';
import { TeamHeaderPointsBadge } from '../PointsBadge';

interface HeaderProps {
  me: Participant;
  team: TeamV0;
  isLeader: boolean;
  miniMode: boolean;
}

const useHeaderDisplayState = (
  miniMode: boolean
): 'display' | 'display-on-hover' | 'hide' => {
  if (miniMode) return 'hide';
  return 'display';
};

export function Header({ team, isLeader, miniMode }: HeaderProps): JSX.Element {
  const [showDropdown, setShowDropdown] = useToggle(false);
  const [renaming, setRenaming] = useToggle(false);
  const ref = useRef<HTMLElement | null>(null);

  const headerDisplayState = useHeaderDisplayState(miniMode);

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useOutsideClick(ref, () => {
    setShowDropdown(false);
  });

  const handleRename = () => {
    setRenaming(true);
    setShowDropdown(false);
  };

  const pointsBadgeIsEnabled = usePointsBadgeIsActive();

  return (
    <div
      className={`flex justify-start items-center ${
        team.membersCount > 1 ? 'self-start' : ''
      }`}
    >
      {/* The Team Member Badge is wrapped with z-10, we usee z-[11] here to 
      make sure the dropdown menu is above them.*/}
      <header
        ref={ref}
        className={`
          max-w-max h-10 self-start 
          bg-lp-black-002 text-white rounded-xl text-sm
          pr-2
          relative z-[11]
          flex items-center justify-center
          transition-opacity duration-500 ease-in-out
          ${
            headerDisplayState === 'hide'
              ? 'opacity-0 pointer-events-off'
              : 'pointer-events-on'
          }`}
      >
        <TeamHeaderPointsBadge />
        <TeamIcon
          className={`fill-current ${
            pointsBadgeIsEnabled ? 'ml-0' : 'ml-4'
          } w-5 h-5`}
        />
        {!renaming && (
          <>
            <span className='mx-2 font-bold min-w-24 whitespace-nowrap'>
              {team.name}
            </span>{' '}
            {isLeader && (
              <button
                className='icon-btn-no-shadow bg-transparent w-7.5 h-7.5 text-white'
                onClick={handleToggleDropdown}
              >
                {showDropdown ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </button>
            )}
          </>
        )}
        {renaming && (
          <RenameTeam
            team={team}
            inputClassName='mx-2'
            handleClose={() => setRenaming(false)}
          />
        )}
        {showDropdown && (
          <ul className='w-40 absolute top-12 right-0 bg-black text-white rounded-xl p-1 text-2xs before:tooltipArrowRightTop'>
            {isLeader && (
              <li
                className='p-3 hover:bg-lp-black-003 rounded-lg cursor-pointer select-none flex items-center justify-start'
                onClick={handleRename}
              >
                <EditIcon />
                <span className='ml-2'>Rename Team</span>
              </li>
            )}
          </ul>
        )}
      </header>

      <BroadcastToggle
        className={`ml-2.5 transition-opacity duration-500 ease-in-out ${
          headerDisplayState === 'hide'
            ? 'opacity-0 pointer-events-off'
            : 'pointer-events-on'
        }`}
      />
    </div>
  );
}
