import { useDeviceAPI } from '../Device';
import {
  DefaultSpeakerDeviceOption,
  type DeviceOption,
  MediaKind,
  NoMicDeviceOption,
  useDeviceState,
} from '../Device';
import { Selection, type SelectionSection } from './Selection';

interface AudioSelectionProps {
  audioInput: {
    enabled: boolean;
    options: DeviceOption[];
  };
  audioOutput: {
    enabled: boolean;
    options: DeviceOption[];
  };
  handleClose: () => void;
}

const AudioSelection = ({
  audioInput,
  audioOutput,
  handleClose,
}: AudioSelectionProps): JSX.Element => {
  const api = useDeviceAPI();
  const { activeAudioInputDeviceOption, activeAudioOutputDeviceOption } =
    useDeviceState();

  const handleAudioInputChange = async (option: DeviceOption) => {
    api.updateActiveDeviceOption(MediaKind.AudioInput, option);
    handleClose();
  };

  const handleAudioOutputChange = async (option: DeviceOption) => {
    api.updateActiveDeviceOption(MediaKind.AudioOutput, option);
    handleClose();
  };

  const sections: SelectionSection[] = [];
  if (audioOutput.enabled) {
    sections.push({
      headerText: 'Select a Speaker',
      availableOptions:
        audioOutput.options.length > 0
          ? audioOutput.options
          : [DefaultSpeakerDeviceOption],
      activeOption: activeAudioOutputDeviceOption || DefaultSpeakerDeviceOption,
      handleOptionChange: handleAudioOutputChange,
    });
  }
  if (audioInput.enabled) {
    sections.push({
      headerText: 'Select a Microphone',
      availableOptions:
        audioInput.options.length > 0
          ? audioInput.options
          : [NoMicDeviceOption],
      activeOption: activeAudioInputDeviceOption,
      handleOptionChange: handleAudioInputChange,
    });
  }

  return <Selection sections={sections} />;
};

export { AudioSelection };
