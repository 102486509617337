import { type ReactNode, useMemo, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { assertExhaustive } from '../../utils/common';
import { useAudienceControlSender } from '../Audience/AudienceControlProviders';
import { Menu, MenuItem, NestedMenuItem } from '../common/ActionMenu';
import { ArrowDownIcon } from '../icons/Arrows';
import { CrownIcon } from '../icons/CrownIcon';
import { EyeIcon } from '../icons/EyeIcon';
import { HostIcon } from '../icons/HostIcon';
import { MicrophoneOffIcon } from '../icons/MicrophoneOffIcon';
import { VenueIcon } from '../icons/VenueIcon';
import { useAmICohost } from '../Player';
import { useCycleTeamCaptains } from '../TeamAPI/TeamV1';
import {
  useCohostAvailableNamedPositions,
  useCohostNamedPosition,
  useCohostPositionManager,
  useCohostVisibility,
} from './CohostPositionManagerProvider';
import { type CohostNamedPosition, type CohostVisibility } from './types';

export function CohostSplitMenuControl(props: { className: string }) {
  const isCohost = useAmICohost();

  if (!isCohost) return null;

  return (
    <div className={`${props.className} group`}>
      <CohostMenu
        renderTrigger={(setTriggerRef) => (
          <button
            ref={setTriggerRef}
            type='button'
            className={`icon-btn relative flex justify-center`}
          >
            <HostIcon className='w-3.5 h-3.5 fill-current' />
          </button>
        )}
      />
    </div>
  );
}

export function CohostMenu(props: {
  renderTrigger?: (
    setTriggerRef: (el: HTMLElement | null) => void
  ) => ReactNode;
}) {
  const [controlledVisibility, setControlledVisibility] = useState(false);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'click',
      placement: 'bottom-start',
      interactive: true,
      visible: controlledVisibility,
      onVisibleChange: setControlledVisibility,
    });
  const hide = useLiveCallback(() => setControlledVisibility(false));

  return (
    <div className='relative flex items-center'>
      {props.renderTrigger ? (
        props.renderTrigger(setTriggerRef)
      ) : (
        <button className='btn' ref={setTriggerRef} type='button'>
          <ArrowDownIcon className='w-3.5 h-3.5 fill-current' />
        </button>
      )}
      {visible && (
        <Menu ref={setTooltipRef} {...getTooltipProps()}>
          <RepositionMenuItem hide={hide} />
          <VisibilityMenuItem hide={hide} />
          <MuteAudienceMenuItem hide={hide} />
          <CycleTeamCaptainsMenuItem hide={hide} />
        </Menu>
      )}
    </div>
  );
}

function getPositionLabel(position: CohostNamedPosition) {
  switch (position) {
    case 'default':
      return 'Move video to corner';
    case 'center':
      return 'Move video to center';
    case 'fullscreen-solo':
      return 'Move video to fullscreen (solo)';
    case 'fullscreen-interview':
      return 'Move video to fullscreen (interview)';
    default:
      assertExhaustive(position);
      return 'Unknown';
  }
}

function RepositionMenuItem(props: { hide: () => void }) {
  const copman = useCohostPositionManager();
  const currPosition = useCohostNamedPosition();
  const handleClick = useLiveCallback((position: CohostNamedPosition) => {
    copman.setNamedPosition(position);
    props.hide();
  });
  const positions = useCohostAvailableNamedPositions();
  const menuItems = useMemo(() => {
    return positions.map((pos) => (
      <MenuItem
        key={pos}
        text={getPositionLabel(pos)}
        onClick={() => handleClick(pos)}
        disabled={currPosition === pos}
      />
    ));
  }, [currPosition, handleClick, positions]);

  return (
    <NestedMenuItem
      icon={<VenueIcon className='w-3.5 h-3.5 fill-current' />}
      text='Change Video Position'
    >
      {menuItems}
    </NestedMenuItem>
  );
}

function VisibilityMenuItem(props: { hide: () => void }) {
  const copman = useCohostPositionManager();
  const currVisibility = useCohostVisibility();
  const handleClick = useLiveCallback((visibility: CohostVisibility) => {
    copman.setVisibility(visibility);
    props.hide();
  });

  return (
    <NestedMenuItem
      icon={<EyeIcon className='w-3.5 h-3.5 fill-current' />}
      text='Change Visibility'
    >
      <MenuItem
        text='Visible'
        onClick={() => handleClick('visible')}
        disabled={currVisibility === 'visible'}
      />
      <MenuItem
        text='Hidden'
        onClick={() => handleClick('hidden')}
        disabled={currVisibility === 'hidden'}
      />
      <MenuItem
        text='Placeholder'
        onClick={() => handleClick('placeholder')}
        disabled={currVisibility === 'placeholder'}
      />
    </NestedMenuItem>
  );
}

function MuteAudienceMenuItem(props: { hide: () => void }) {
  const audienceControl = useAudienceControlSender();
  const handleClick = useLiveCallback(async () => {
    await audienceControl.mute();
    props.hide();
  });

  return (
    <MenuItem
      text='Mute Audience'
      icon={<MicrophoneOffIcon />}
      onClick={handleClick}
    />
  );
}

function CycleTeamCaptainsMenuItem(props: { hide: () => void }) {
  const cycleTeamCaptains = useCycleTeamCaptains();
  const handleClick = useLiveCallback(async () => {
    await cycleTeamCaptains();
    props.hide();
  });

  return (
    <MenuItem
      text='Cycle Team Captains'
      icon={<CrownIcon />}
      onClick={handleClick}
    />
  );
}
