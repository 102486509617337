import { type ReactNode, useEffect, useRef, useState } from 'react';

import { useAnalytics } from '../../analytics/AnalyticsContext';
import {
  DefaultLanguageOption,
  findLanguageOptionOrDefault,
  type SupportedLanguages,
} from '../../i18n/language-options';
import { apiService } from '../../services/api-service';
import { assertExhaustive } from '../../utils/common';
import { useOndGameState, useReceivedIsLiveGamePlay } from '../Game/hooks';
import {
  ClosedCaptionsDisabledIcon,
  ClosedCaptionsEnabledIcon,
} from '../icons/ClosedCaptionsIcon';
import { SettingIcon } from '../icons/SettingIcon';
import { useMyI18nSettings } from '../Settings/useMyI18nSettings';
import { type ISubtitlesManager } from '../VoiceOver/LocalSubtitlesManager';
import { useGlobalSubtitlesManager } from '../VoiceOver/SubtitlesManagerProvider';
import {
  ClosedCaptionsAnalytics,
  useGetSharedTrackingProps,
} from './ClosedCaptionsAnalytics';
import {
  configureExpireDurationMs,
  configureLineCount,
  configureLineLength,
} from './MetricsCreator';
import { SubtitlesPrinter } from './SubtitlesPrinter';

export function ClosedCaptionsControl(props: {
  className: string;
  openSettingsModal: Nullable<() => void>;
  tooltipSlot: ReactNode;
  tooltipCtrl: Nullable<(show: boolean) => void>;
}) {
  const { i18nSettings, update } = useMyI18nSettings();
  const currentSubtitlesLocale = findLanguageOptionOrDefault(
    i18nSettings?.value?.subtitlesLocale
  );

  const analytics = useAnalytics();
  const [ccAnalytics] = useState(() => new ClosedCaptionsAnalytics(analytics));
  const getAnalyticsExtra = useGetSharedTrackingProps();
  const isLiveGame = useReceivedIsLiveGamePlay();
  const subman = useGlobalSubtitlesManager();

  const handleToggle = () => {
    const next = !i18nSettings?.value?.subtitles;
    update({ subtitles: next });
    ccAnalytics.trackClosedCaptionsClicked(next, getAnalyticsExtra());
  };

  return isLiveGame ? null : (
    <div className={`${props.className} group`}>
      {props.tooltipSlot}
      <button
        type='button'
        className={`icon-btn relative flex flex-col justify-center`}
        onClick={handleToggle}
      >
        {i18nSettings?.value?.subtitles ? (
          <ClosedCaptionsEnabledIcon />
        ) : (
          <ClosedCaptionsDisabledIcon />
        )}
      </button>
      <SubtitlesArea
        subman={subman}
        currentLanguage={currentSubtitlesLocale}
        className={`absolute left-full bottom-0 px-4.5 pointer-events-off ${
          // Always put this in the DOM so that the subtitles are immediately
          // visible if requested mid-sentence.
          i18nSettings?.value?.subtitles ? 'visible' : 'invisible'
        }`}
      />

      <button
        type='button'
        className='border border-secondary icon-btn absolute w-auto h-auto -top-2 -right-2 text-white hover:bg-gray-600 active:bg-secondary p-0.75'
        onClick={() => {
          props.openSettingsModal?.();
          ccAnalytics.trackClosedCaptionsSettingsClicked(getAnalyticsExtra());
          props.tooltipCtrl?.(false);
        }}
      >
        <SettingIcon className='w-3 h-3 fill-current' />
      </button>
    </div>
  );
}

export function SubtitlesArea(props: {
  className: string;
  currentLanguage: { label: string; value: string };
  subman: ISubtitlesManager;
  isV2?: boolean;
}) {
  // We use the types just to make the utilities easier to write / maintain, but
  // the incoming value is unchecked.
  const currentLanguageCode = props.currentLanguage.value as SupportedLanguages;

  const ref = useRef<null | HTMLDivElement>(null);
  const [printer] = useState(
    () =>
      new SubtitlesPrinter(
        ref,
        configureLineLength(currentLanguageCode, props.isV2),
        configureExpireDurationMs(currentLanguageCode),
        configureLineCount(currentLanguageCode)
      )
  );

  useEffect(() => {
    return props.subman.on('script-now', async (line) => {
      if (DefaultLanguageOption.value === currentLanguageCode) {
        printer.accept(line, currentLanguageCode);
      } else {
        const translated = await apiService.translation.translate({
          text: line,
          sourceLanguageCode: DefaultLanguageOption.value,
          targetLanguageCode: currentLanguageCode,
        });
        printer.accept(
          translated.data.text,
          translated.data.targetLanguageCode
        );
      }
    });
  }, [printer, currentLanguageCode, props.subman]);

  const previousLanguageRef = useRef(props.currentLanguage);
  useEffect(() => {
    if (currentLanguageCode !== previousLanguageRef.current.value) {
      printer.clear();
      printer.updateMaxLineLength(configureLineLength(currentLanguageCode));
      printer.accept(
        '[ updating translation. . . ]',
        DefaultLanguageOption.value
      );
      previousLanguageRef.current = props.currentLanguage;
    }
  }, [currentLanguageCode, printer, props.currentLanguage]);

  const ondGameState = useOndGameState();

  useEffect(() => {
    switch (ondGameState) {
      case 'paused':
        printer.pause();
        break;

      case 'running':
        printer.resume();
        break;

      case 'ended':
        printer.clear();
        break;

      case null:
      case 'preparing':
      case 'resuming':
        break;

      default:
        assertExhaustive(ondGameState);
        break;
    }
  }, [ondGameState, printer]);

  useEffect(() => {
    return () => {
      printer.destroy();
    };
  }, [printer]);

  return (
    <div className={`${props.className}`} ref={ref}>
      {/* Subtitles will be printed here */}
    </div>
  );
}
