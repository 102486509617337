import { type Reducer, useEffect, useReducer } from 'react';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useMyInstance } from '../../hooks/useMyInstance';
import {
  type TaskQueue,
  useStatsAwareTaskQueue,
} from '../../hooks/useTaskQueue';
import { useVenueMode } from '../../hooks/useVenueMode';
import {
  ClassicTeamView,
  RemoteStreamStateProvider,
  TownhallTeamView,
} from '../../pages/Audience/Team';
import { useCleanupNotificationAfterSwitchTeam } from '../../store/teamInviteHooks';
import { type ClientType, ClientTypeUtils, isStaff } from '../../types/user';
import { VenueMode } from '../../types/venue';
import { assertExhaustive } from '../../utils/common';
import { BroadcastMembersIndicator } from '../Broadcast/BroadcastMembersIndicator';
import { useIsAnyoneBroadcasting } from '../Broadcast/BroadcastProvider';
import { LobbyBroadcastBundle } from '../Broadcast/Utilities';
import { CrowdView } from '../CrowdFrames';
import { CrowdViewContainer } from '../CrowdFrames/CrowdView';
import { DoubleDownArrow, DoubleUpArrow } from '../icons/Arrows';
import { useLiteModeEnabled } from '../LiteMode';
import { useAmICohost } from '../Player';
import { useIsTeamsOnTop } from '../TeamAPI/TeamV1';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';

type ViewStates = 'lobby' | 'team' | 'crowd' | 'crowd-avatar-selected' | 'host';

type ViewActions =
  | 'lobby-mode-switched'
  | 'venue-mode-switched'
  | 'game-mode-switched'
  | 'pointer-hovering-crowd'
  | 'pointer-leaving'
  | 'pointer-selected'
  | 'pointer-outside-click'
  | 'expand-collapse-button-click';

function ExpandCollapseButton(props: {
  viewState: ViewStates;
  isHost: boolean;
  isAnyoneBroadcasting: boolean;
  onClick: () => void;
  className: string;
}) {
  let labelArrows: {
    label: string;
    arrows: JSX.Element;
    button: boolean;
  } | null = null;

  // The empty space in the arrows is important so the container is always two
  // "lines" tall.
  const empty = {
    label:
      props.isAnyoneBroadcasting || props.isHost
        ? ' '
        : `You can't hear these users, and they can't hear you.`,
    arrows: <span className='w-2.5 h-2.5 block'></span>,
    button: false,
  };

  switch (props.viewState) {
    case 'crowd-avatar-selected':
    case 'crowd': {
      labelArrows = props.isHost
        ? empty
        : {
            label: 'Collapse Crowd',
            arrows: <DoubleDownArrow className='w-2.5 h-2.5' />,
            button: true,
          };
      break;
    }

    case 'host': {
      labelArrows = empty;
      break;
    }

    case 'lobby': {
      labelArrows = {
        label: '',
        arrows: <span className='w-2.5 h-2.5 block'></span>,
        button: false,
      };
      break;
    }

    case 'team': {
      labelArrows = {
        label: 'Expand Crowd',
        arrows: <DoubleUpArrow className='w-2.5 h-2.5' />,
        button: true,
      };
      break;
    }

    default: {
      assertExhaustive(props.viewState);
      labelArrows = empty;
      break;
    }
  }

  return (
    <div
      className={`
        pointer-events-off text-3xs text-white
        flex justify-center
        ${props.className}
      `}
    >
      {!labelArrows.button ? (
        <div className='flex flex-col items-center justify-end whitespace-nowrap'>
          {labelArrows.arrows}
          <span>{labelArrows.label}</span>
        </div>
      ) : (
        <button
          type='button'
          className={`
            w-40 h-7
            flex flex-row items-center justify-center whitespace-nowrap
            pointer-events-on
            btn
            bg-secondary bg-opacity-20 hover:bg-opacity-100 rounded-full
      `}
          onClick={props.onClick}
        >
          {labelArrows.arrows}
          <span className='ml-2'>{labelArrows.label}</span>
        </button>
      )}
    </div>
  );
}

function useViewState(lobbyMode: boolean, myClientType: ClientType) {
  return useReducer<Reducer<ViewStates, ViewActions>>(
    (state, action) => {
      switch (action) {
        case 'pointer-hovering-crowd': {
          return state === 'team' ? 'crowd' : state;
        }

        case 'pointer-leaving': {
          return state === 'crowd' ? 'team' : state;
        }

        case 'pointer-selected': {
          return 'crowd-avatar-selected';
        }

        case 'lobby-mode-switched': {
          return 'lobby';
        }

        case 'venue-mode-switched': {
          return state !== 'crowd' ? 'team' : state;
        }

        case 'game-mode-switched': {
          return lobbyMode ? 'lobby' : 'team';
        }

        case 'pointer-outside-click': {
          return ClientTypeUtils.isHost(myClientType)
            ? 'host'
            : lobbyMode
            ? 'lobby'
            : 'team';
        }

        case 'expand-collapse-button-click': {
          switch (state) {
            case 'crowd-avatar-selected':
            case 'crowd': {
              return 'team';
            }

            case 'host':
            case 'lobby': {
              return state;
            }

            case 'team':
              return 'crowd';

            default: {
              assertExhaustive(state);
              return state;
            }
          }
        }

        default:
          assertExhaustive(action);
      }

      return state;
    },
    ClientTypeUtils.isHost(myClientType) ? 'host' : 'lobby'
  );
}

function ClassicLayoutInternal(props: {
  broadcastWidgetsEnabled?: boolean;
  children?: (viewState: ViewStates) => JSX.Element | null;
}): JSX.Element {
  const myClientType = useMyClientType();
  const venueMode = useVenueMode();
  const lobbyMode = venueMode === VenueMode.Lobby;
  const crowdViewFeatureEnabled = useFeatureQueryParam('crowd-view');
  const liteMode = useLiteModeEnabled();
  const crowdViewEnabled = crowdViewFeatureEnabled && !liteMode;
  const isAnyoneBroadcasting = useIsAnyoneBroadcasting();
  const [viewState, handleMaybeViewState] = useViewState(
    lobbyMode,
    myClientType
  );
  const crowdViewMode =
    viewState === 'crowd' ||
    viewState === 'host' ||
    viewState === 'crowd-avatar-selected'
      ? 'full'
      : 'collapsed';

  const isViewStateLobbyOrTeam = viewState === 'lobby' || viewState === 'team';

  useEffect(() => {
    if (ClientTypeUtils.isHost(myClientType)) return;
    if (lobbyMode) {
      handleMaybeViewState('lobby-mode-switched');
    } else {
      handleMaybeViewState('venue-mode-switched');
    }
  }, [handleMaybeViewState, lobbyMode, myClientType]);

  return (
    <>
      <div
        className={`
        z-5 /* Keep team names above "Expand Crowd" button */
        flex flex-col items-center w-full
        transform-gpu transition-transform duration-700
        pb-10
        ${
          isViewStateLobbyOrTeam
            ? 'translate-y-35 xl:translate-y-[10.25rem]'
            : viewState === 'host' || ClientTypeUtils.isHost(myClientType)
            ? '-translate-y-0'
            : 'translate-y-0'
        }
      `}
      >
        {props.children?.(viewState)}
        {venueMode === VenueMode.Lobby &&
          props.broadcastWidgetsEnabled &&
          ClientTypeUtils.isHost(myClientType) && (
            <LobbyBroadcastBundle classNames='mb-2' />
          )}
        {crowdViewEnabled ? (
          <CrowdView
            classNames={
              ClientTypeUtils.isAudience(myClientType) && viewState === 'lobby'
                ? 'invisible'
                : ''
            }
            handleSelected={() => handleMaybeViewState('pointer-selected')}
            viewMode={crowdViewMode}
          />
        ) : (
          <CrowdViewContainer
            className={
              ClientTypeUtils.isAudience(myClientType) && viewState === 'lobby'
                ? 'invisible'
                : ''
            }
          />
        )}
      </div>
      <div className='z-5 w-[42rem] xl:w-208 absolute bottom-0 pb-2.5 pt-3 grid grid-cols-8 gap-4'>
        {venueMode === VenueMode.Game && (
          <div
            className={`col-span-3 self-end ${
              ClientTypeUtils.isHost(myClientType)
                ? 'absolute -top-33 xl:-top-40'
                : ''
            }`}
          >
            {props.broadcastWidgetsEnabled && <BroadcastMembersIndicator />}
          </div>
        )}
        {crowdViewEnabled && !lobbyMode && (
          <ExpandCollapseButton
            className={`col-span-2 self-end`}
            viewState={viewState}
            isHost={ClientTypeUtils.isHost(myClientType)}
            isAnyoneBroadcasting={isAnyoneBroadcasting}
            onClick={() => handleMaybeViewState('expand-collapse-button-click')}
          />
        )}
      </div>
    </>
  );
}

type TeamView = 'default' | 'minified' | 'disabled';

// _minified_ is not supported in this component
function PinnedBottomLayout(props: {
  taskQueue: TaskQueue;
  className?: string;
  teamView: TeamView;
}): JSX.Element {
  const myClientType = useMyClientType();
  const participant = useMyInstance();
  const { teamView } = props;

  return (
    <div
      className={`
        w-full h-full absolute px-72
        overflow-hidden 
        flex flex-col items-center justify-end
        pointer-events-off
        ${props.className ?? ''}`}
    >
      <ClassicLayoutInternal broadcastWidgetsEnabled>
        {(viewState) => {
          if (
            ClientTypeUtils.isAudience(myClientType) &&
            teamView === 'default'
          ) {
            const isViewStateLobbyOrTeam =
              viewState === 'lobby' || viewState === 'team';
            return (
              <ClassicTeamView
                participant={participant}
                miniMode={!isViewStateLobbyOrTeam}
                className={`${
                  // TODO: it is very inefficient to animate padding. Instead, use
                  // padding to do the primary layout, and use transforms to correct
                  // during different states.
                  viewState === 'team' ? 'pb-18' : 'pb-9'
                }`}
                taskQueue={props.taskQueue}
              />
            );
          }
          return <></>;
        }}
      </ClassicLayoutInternal>
    </div>
  );
}

function PinnedTopLayout(props: {
  taskQueue: TaskQueue;
  className?: string;
  teamView: TeamView;
}): JSX.Element {
  const { teamView } = props;
  const me = useMyInstance();
  const amICohost = useAmICohost();

  // This width is _measured_ to determine the size of the stream views / seats,
  // based on the total number and product constraints (e.g. max two rows, each
  // row has X columns, etc). Note that the Townhall view will be offcenter if
  // the left and right padding are not equal, unfortunately. Since the upper
  // right coordinator/minigame widget is a fixed size at all breakpoints
  // (non-responsive), we have to use a fixed size here on the right side. But
  // then to keep things centered, that same fixed size must be used on the
  // left, too.
  const townhallSafeWidthClasses = `w-full pl-60 pr-60`;

  return (
    <div
      className={`
        w-full h-full absolute
        overflow-hidden
        flex flex-col items-center justify-between
        pointer-events-off
        ${townhallSafeWidthClasses}
        ${props.className ?? ''}`}
    >
      <div className={`z-5 flex flex-col items-center w-full relative`}>
        <TownhallTeamView taskQueue={props.taskQueue} teamView={teamView} />
      </div>
      {/* This is added primarily so that the spectator can see the crowd view  */}
      {(isStaff(me) || amICohost) && (
        <div>
          <ClassicLayoutInternal />
        </div>
      )}
    </div>
  );
}

export function MiddlePanel(props: {
  className?: string;
  teamView?: 'default' | 'minified';
}): JSX.Element {
  const teamsOnTop = useIsTeamsOnTop();
  useCleanupNotificationAfterSwitchTeam();
  const taskQueue = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-townhall-join-ms',
  });
  const teamViewFlag = useFeatureQueryParam('team-view');
  const teamView =
    teamViewFlag === false ? 'disabled' : props.teamView ?? 'default';

  return (
    <RemoteStreamStateProvider>
      {teamsOnTop ? (
        <PinnedTopLayout {...props} taskQueue={taskQueue} teamView={teamView} />
      ) : (
        <PinnedBottomLayout
          {...props}
          taskQueue={taskQueue}
          teamView={teamView}
        />
      )}
    </RemoteStreamStateProvider>
  );
}
